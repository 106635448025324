<template>
  <div id="app">
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
    >
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'A-App',
  components: {
  }
}
</script>

<style>

.md-menu-content.md-select-menu {
  width: auto;
  max-height: 60vh;
  /*max-width: 400px !important;*/
}

.md-menu-content{
  z-index: 1055 !important;
}

.md-list-item-text {
  white-space: normal !important; /* Allows text to wrap onto multiple lines */
  word-wrap: break-word; /* Breaks long words */
}

.md-title {
  font-size: 20px !important;
}
.md-select-menu.md-menu-content-bottom-start.md-menu-content-small.md-menu-content.md-theme-default {
    z-index: 9999 !important;
    width: 100%;
}

.md-menu-content-bottom-start.md-menu-content-small.md-menu-content {
  width: auto !important; /* Or set the desired value */
  max-width: none !important; /* Or set the desired value */
}

.md-input {
  margin: 0 auto;
}

</style>
